import LabelWithInfoTooltip from "./Components/InfoToolTips";

const DetailRow = ({ data, label, highlight , enableEdit ,editFlag , idField , tooltipId , tooltipInfo}) => {
  return (
    <>
      {data && (
        <>
          <div>
            {highlight ? (
              <>
                <span className="bg-warning fw-bold">{label}:</span>
                {tooltipId && tooltipInfo && (
                  <LabelWithInfoTooltip info={tooltipInfo} id={tooltipId} />
                )}
              </>
            ) : (
              <>
                <span className="fw-bold">{label}: </span>
                {tooltipId && tooltipInfo && (
                  <LabelWithInfoTooltip info={tooltipInfo} id={tooltipId} />
                )}
              </>
            )}
          </div>
          <div id={idField} className={enableEdit ? "enableEdit" : ""}>
            {data}
          </div>
        </>
      )}
    </>
  );
};

export default DetailRow;