import CryptoJS from 'crypto-js';

const secretKey = '7EGSJRCYAxvfMvLKdLoCQ6dO4jAZPW9u3LIQW4l7hLM='; // Replace with your actual secret key

export const encryptData = (plaintext) => {
    const key = CryptoJS.enc.Base64.parse(secretKey);
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(plaintext), key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString();
  };
  
  // Decrypt ciphertext with AES
  export const decryptData = (ciphertext) => {
    const key = CryptoJS.enc.Base64.parse(secretKey);
    const bytes = CryptoJS.AES.decrypt(ciphertext, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  export const handleEncrypt = (plaintext) => {
    const ciphertext = CryptoJS.AES.encrypt(plaintext, secretKey).toString();
    return ciphertext;
  };

  export const handleDecrypt = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    const originalMessage = bytes.toString(CryptoJS.enc.Utf8);
    return originalMessage;
  };
