import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Tooltip } from 'react-tooltip';  
import 'react-tooltip/dist/react-tooltip.css'; 

const LabelWithInfoTooltip = ({  info ,id }) => {
  
    return (
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
        <span data-tooltip-id={`infoTooltip-${id}`} style={{ marginLeft: '5px', cursor: 'pointer'  }}>
        <FontAwesomeIcon icon={faCircleInfo} size="sm" style={{color: "#00dfc4",}} />
        </span>
        <Tooltip id={`infoTooltip-${id}`} place="top" effect="solid" style={{  width: "200px", wordWrap: "break-word", wordBreak: "break-word" }}>
          {info}
        </Tooltip>
      </span>
      );
    };



export default LabelWithInfoTooltip;
