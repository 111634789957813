
export const API_BASE_URL = "https://digitalnow.molecularconnections.com/digital_now_services/croplife_api";

export const LOGIN_NEEDED = true;

//export const API_BASE_URL = "http://localhost:7074/croplife_api";

//export const API_BASE_URL ="http://14.99.187.2:7074/croplife_api";

//export const API_BASE_URL = "https://digitalnow-test.molecularconnections.com/digital_now_services_test/croplife_api_test";


