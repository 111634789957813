import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";

import Landing from "./Pages/Landing/Landing";
import LabelView from "./Pages/LabelView/LabelView";
import Footer from "./Pages/MasterLayout/Footer";
import Header from "./Pages/MasterLayout/Header";

const RoutesList = () => {
  const RootLayout = (
    <div>
      <Footer />
    </div>
  );

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/landing" />} />

      <Route
        path="/browse"
        element={
          <>
            <Header />

            <section
              style={{ minHeight: "80vh" }}
              className="container-xxl my-2"
            >
              <main>
                <LabelView />
              </main>
            </section>
          </>
        }
      />

      <Route path="/landing" element={<Landing />} />
    </Routes>
  );
};

export default RoutesList;
