import DateFormat from "./DateFormat";

const Allcomments = ({ data, sectionRef }) => {
  return (
    <>
      <div className="card  mb-3">
        <h5 class="card-header ">All Comments By Added On</h5>

        <div ref={sectionRef} className="card-body ">
          <div className="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th style={{ width: "10%" }} scope="col">
                    Section No
                  </th>
                  <th scope="col">Comment</th>
                  <th scope="col">Added by</th>
                  <th scope="col">Added on</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.length >= 1 &&
                  data.map((each, i) => (
                    <tr>
                      <th scope="row">{each.sectionNo}</th>

                      <td>{each.comment}</td>
                      <td>{each.username}</td>
                      <td>
                        {each.timeStamp}
                      </td>
                    </tr>
                  ))}

                {(!data || data.length < 1) && (
                  <tr>
                    <td colSpan={2}>No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Allcomments;
