import React, { useEffect, useState } from "react";
import { Modal, InputGroup, Form, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import styles from "./Login.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  closeLoginModal,
  openLoginModal,
} from "../../Redux/Action/login.action";
import logo from "../../assets/mcLogo.png";
import { toast } from "react-toastify";
import { authLogin } from "..//auth_service/AuthServiceHelper";
import AxiosService from "../../Utils/AxiosService";
import { tokenRefreshed } from "../../Redux/Action/token.action";

function Login() {
  const dispatch = useDispatch();
  const modalToggle = useSelector((state) => state.login.loginModalToggle);
  const [invalidCredentials, setInvalidCredentials] = useState("");
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [forgotPasswordToggle, setForgotPasswordToggle] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [verificationMailToggle, setVerificationMailToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formValues, setFormValues] = useState({ username: "", password: "" });
  const [formErrors, setFormErrors] = useState({ username: "", password: "" });

  const validEmailRegex = RegExp(
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
  );
  const [disabledUserEmail, setDisableduserEmail] = useState("");
  const apiService = new AxiosService();

  /**
   * Form validations - email & password, set form input values
   */

  function handleChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: value.length > 0 && "" });
  }

  function handleValidation(event) {
    const { name, value } = event.target;
    switch (name) {
      case "username":
        setFormErrors({
          ...formErrors,
          username:
            value.length > 0
              ? forgotPasswordToggle
                ? validEmailRegex.test(value)
                  ? ""
                  : "Please enter a valid Email"
                : value.length > 5
                ? ""
                : "Email address must be min 6 characters"
              : forgotPasswordToggle
              ? "*Email is required"
              : "*Email address is required",
        });
        break;
      case "password":
        setFormErrors({
          ...formErrors,
          password:
            value.length > 0
              ? value.length > 5
                ? ""
                : " Password must be 6 characters"
              : "*Password is required",
        });
        break;
      default:
        break;
    }
  }

  /**
   * Form submit on validation - auth service call
   * on success redirect to manage subscripitons page
   */

  function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    if (forgotPasswordToggle) {
      const data = { email: formValues.username };
      apiService
        .forgotPassword(data)
        .then((response) => {
          setIsLoading(false);
          setInvalidCredentials("");
          resetFormFields();
          setErrorMessage("");
          setForgotPasswordToggle(false);
          dispatch(closeLoginModal());
          toast.success("Reset password link has been sent successfully", {
            autoClose: 3000,
          });
          setTimeout(() => {
            dispatch(openLoginModal());
          }, 3000);
        })
        .catch((error) => {
          const errorData = error?.response?.data;
          setIsLoading(false);
          setErrorMessage(decodeURIComponent(errorData?.error_description));
          setInvalidCredentials("");
          toast.error(
            errorData.error_description
              ? errorData.error_description
              : "Something went wrong"
          );
        });
    } else {
      const config = {
        url: "api/v1/oauth/getToken",
        method: "post",
        data: {
          username: formValues.username,
          password: formValues.password,
          grantType: "password",
        },
      };
      authLogin(config)
        .then((response) => {
          setIsLoading(false);
          setInvalidCredentials("");
          resetFormFields();
          handleModalClose();
          dispatch(tokenRefreshed());
          setTimeout(() => {
            window.location.reload();
          }, 200);
          toast.success("Successfully Logged In!", {
            autoClose: 3000,
          });
        })
        .catch((error) => {
          setIsLoading(false);
          const errorMessage = error.response;
          switch (errorMessage.status) {
            case 400:
            case 401:
              setInvalidCredentials(errorMessage.data.error_description);
              resetFormPassword();
              if (errorMessage.data.error_description === "User is disabled") {
                setDisableduserEmail(formValues.username);
                setTimeout(() => {
                  setVerificationMailToggle(true);
                }, 500);
              }
              break;
            case 406:
              setInvalidCredentials(errorMessage.data.error_description);
              resetFormPassword();
              if (errorMessage.data.error_description === "User is disabled") {
                setDisableduserEmail(formValues.username);
                setTimeout(() => {
                  setVerificationMailToggle(true);
                }, 500);
              }
              break;
            default:
              break;
          }
        });
    }
  }

  function handleToggle() {
    setForgotPasswordToggle(false);
    setErrorMessage("");
    setFormValues({ ...formValues, username: "" });
    setFormErrors({ ...formErrors, username: "" });
  }

  function handleForgotPassword() {
    setInvalidCredentials("");
    setForgotPasswordToggle(true);
    setFormValues({ ...formValues, username: "" });
    setFormErrors({ ...formErrors, username: "" });
  }

  function handleVerificationMailToggle() {
    setVerificationMailToggle(false);
    setErrorMessage("");
  }

  function validateForm() {
    if (forgotPasswordToggle || verificationMailToggle) {
      return validEmailRegex.test(formValues.username);
    } else {
      return formValues.username.length >= 6 && formValues.password.length >= 6;
    }
  }

  function resetFormFields() {
    setFormValues({ ...formValues, username: "", password: "" });
  }

  function resetFormPassword() {
    setFormValues({ ...formValues, password: "" });
  }

  function handleModalClose() {
    setInvalidCredentials("");
    resetFormFields();
    setFormErrors({ ...formErrors, username: "", password: "" });
    setForgotPasswordToggle(false);
    setVerificationMailToggle(false);
    dispatch(closeLoginModal());
  }

  useEffect(() => {}, [modalToggle]);

  useEffect(() => {
    setFormValues({ ...formValues, username: disabledUserEmail });
  }, [verificationMailToggle]);

  return (
    <Modal
      show={modalToggle}
      dialogClassName={styles.modalWidth}
    >
    

      <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
        <h4 className="text-center">
          {!forgotPasswordToggle ? "Log in" : "Forgot password"}
        </h4>
        {errorMessage && (
          <p
            className={classNames(
              "text-center text-danger mb-0 mt-3 mx-3",
              styles.validators
            )}
          >
            {forgotPasswordToggle && errorMessage}
          </p>
        )}

        <form className="px-3 py-2 w-100" onSubmit={handleSubmit}>
          {invalidCredentials && (
            <div className={styles.validators}>
              <span>{invalidCredentials}</span>
            </div>
          )}
          {!verificationMailToggle ? (
            <>
              <label htmlFor="username">
                {forgotPasswordToggle
                  ? "Registered Email Address"
                  : "Email Address"}
              </label>
              <InputGroup className="mt-2">
                <Form.Control
                  aria-label="Email Address"
                  className={styles.customFormField}
                  type="text"
                  name="username"
                  value={formValues.username}
                  placeholder={
                    forgotPasswordToggle
                      ? "Enter your Registered Email Address"
                      : "Enter your Email Address"
                  }
                  onChange={(event) => handleChange(event)}
                  onBlur={(event) => handleValidation(event)}
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
              <span className={classNames(styles.formErrors, "d-block")}>
                {formErrors.username}
              </span>
            </>
          ) : (
            <>
              <li>
                <span>
                  Check your inbox and spam folder for verification mail.
                </span>
              </li>
              <li>
                <span>
                  You can request to resend email on your registered email
                  address <strong>{formValues.username}</strong>.
                </span>
              </li>
              <br />
            </>
          )}
          {forgotPasswordToggle ? (
            <>
              {!isLoading ? (
                <button
                  className="btn btn-primary mt-3 btnDefault w-100"
                  disabled={!validateForm()}
                  type="submit"
                >
                  Send password reset link
                </button>
              ) : (
                <button className="btn btn-primary btnDefault w-100">
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span>Sending...</span>
                </button>
              )}
              <h6 style={{ fontWeight: 400, margin: "0.25rem 0rem" }}>
                A password reset link will be sent to the Registered Email
                Address
              </h6>
              <div className={styles.formFooter}>
                <span>Remember password. Go back to</span>
                <button
                  className={styles.customLink}
                  onClick={() => handleToggle()}
                >
                  Login
                </button>
              </div>
            </>
          ) : (
            <>
              {verificationMailToggle === false ? (
                <>
                  <label htmlFor="password">Password</label>
                  <InputGroup
                    className={classNames("mt-2", styles.customInput)}
                  >
                    <Form.Control
                      className={styles.customFormField}
                      type={passwordToggle ? "text" : "password"}
                      aria-label="Password"
                      name="password"
                      value={formValues.password}
                      placeholder="Enter your password"
                      onChange={(event) => handleChange(event)}
                      onBlur={(event) => handleValidation(event)}
                      aria-describedby="basic-addon2"
                    />
                    <InputGroup.Text>
                      {passwordToggle ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={() => setPasswordToggle(!passwordToggle)}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          onClick={() => setPasswordToggle(!passwordToggle)}
                        />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                  <span className={classNames(styles.formErrors, "d-inline")}>
                    {formErrors.password}
                  </span>


                  {!isLoading ? (
                    <button
                      className="btn btn-primary mt-3 btnDefault w-100"
                      disabled={!validateForm()}
                      type="submit"
                    >
                      Sign in
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary mt-3 btnDefault w-100"
                      disabled={!isLoading}
                    >
                      <span>
                        Loading
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </span>
                    </button>
                  )}
                </>
              ) : (
                <>
                  {!isLoading ? (
                    <button
                      className="btn btn-primary btnDefault w-100"
                      disabled={!validateForm()}
                      type="submit"
                    >
                      Send password reset link
                    </button>
                  ) : (
                    <button className="btn btn-primary btnDefault w-100">
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span>Sending...</span>
                    </button>
                  )}
                  <div className={styles.formFooter}>
                    <span>Go back to</span>
                    <button
                      className={styles.customLink}
                      onClick={() => handleVerificationMailToggle()}
                    >
                      Login
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Login;
