import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import AxiosService from "../../../Utils/AxiosService";


const ValidationComments = ({ label,data, sectionRef }) => {
    const apiService = new AxiosService();
    const [validationComment,setValidationComment] = useState("");



    useEffect(() => {
        apiService
          .getValidationComment(label)
          .then((response) => {
            if (response.data) {
                setValidationComment(response.data.validation);
            }
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              toast.error(error.response.data.message);
            } else {
              toast.error("Something went wrong!");
            }
          });
      }, [data]);

      
  return (
    <div ref={sectionRef}>
    {validationComment && 
        <div className="card mb-3">
        <h5 class="card-header ">Validation Comment</h5>
        <div className="card-body " ref={sectionRef}>
          <div className="table-responsive" style={{ whiteSpace: 'pre-wrap', wordBreak:'break-word' }}>{validationComment}</div>
        </div>
      </div>
    }
    </div>
   
  );
};

export default ValidationComments;
