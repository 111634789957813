import React from "react";
import "./Discliamer.css";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";

const DisclaimerPopup = ({ show, handleAcknowledge , rejectAcknowledge }) => {
  return (
    <Modal
      show={show}
      onHide={handleAcknowledge}
      backdrop="static"
      keyboard={false}
      // size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="disclaimer-div">
          <strong className="disclaimer-header">Disclaimer:</strong> By
          accessing this tool, you acknowledge and agree to the following:
          <br></br>
          <br></br>
          <p>
            <strong className="disclaimer-header">
              Intellectual Property Protection:
            </strong>{" "}
            All intellectual property, including but not limited to the code,
            interface, text, design, and any other content within this software
            tool, is the sole property of Molecular Connections (“
            <strong>MC</strong>”). Unauthorized use, reproduction, or
            distribution of any part of this tool is strictly prohibited and may
            result in legal action.<br></br>
            <br></br>
            <strong className="disclaimer-header">Tool Ownership:</strong> This
            tool, including all features, functionalities, screens, and
            associated data, is the proprietary asset of MC. Any modifications,
            improvements, or adaptations made during use shall remain under the
            ownership of MC. <br></br>
            <br></br>
            <strong className="disclaimer-header">
              Tool Features & Functionality:
            </strong>
            The features and functionalities of this tool are provided "as-is"
            and may be subject to modifications or discontinuation at MC's sole
            discretion. MC is not liable for any issues arising from the use of
            these features. <br></br>
            <br></br>
            <strong className="disclaimer-header">Screen Displays:</strong> The
            appearance and layouts of screens in this tool may vary, and any
            reliance on specific layouts should be verified periodically with
            updates provided by MC. <br></br>
            <br></br>
            <strong className="disclaimer-header">
              {" "}
              Data Warranty Disclaimer:
            </strong>{" "}
            While we strive to provide accurate and reliable data, MC makes no
            warranties, express or implied, regarding the data's accuracy,
            completeness, or fitness for a particular purpose. MC will not be
            responsible for any loss or damage resulting from data inaccuracies
            or omissions.
            <br></br>
            <br></br>
            By proceeding with login, you agree to these terms and conditions as
            they apply to your access and usage of this tool.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
      <Button
          variant="danger"
          className="btn btn-sm"
          onClick={rejectAcknowledge}
        >
            <FontAwesomeIcon icon={faXmark} className="me-2"/>
          reject
        </Button>
        <Button
          variant="success"
          className="btn btn-sm"
          onClick={handleAcknowledge}
        >
            <FontAwesomeIcon icon={faCheck} className="me-2"/>
          acknowledge
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DisclaimerPopup;
