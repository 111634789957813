import { ActionType } from "../Action/Types"

const initialState = {
    loginModalToggle: false,
}

const loginReducer = (state=initialState, action) => {
    switch(action.type){
        case ActionType.LOGIN_MODAL_OPEN:
            return{...state, loginModalToggle: action.payload}
        case ActionType.LOGIN_MODAL_CLOSE:
            return{...state, loginModalToggle: action.payload}
        default:
            return state;
    }
}

export default loginReducer;