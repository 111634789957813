import {combineReducers} from "redux"

import loginReducer from "./Login.reducer"
import tokenReducer from "./Token.reducer"

const reducer = combineReducers({
    login: loginReducer,
    token: tokenReducer
})

export default reducer