import React, { useEffect, useState } from 'react';
import AxiosService from "../../../Utils/AxiosService";
import { toast } from "react-toastify";

const EditedReport = ({label , data, sectionRef }) => {
  const apiService = new AxiosService();
  const [nodes, setNodes] = useState([]);
  const [saveFlag, setSaveFlag] = useState(false);

  useEffect(() => {
    apiService
      .getEditedReportByLabel(label)
      .then((response) => {
        if (response.data) {
          setNodes(response.data);
          setSaveFlag(false);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      });

  }, [saveFlag]);

  useEffect(() =>{
    setSaveFlag(true);
  },[data])


  const handleClick = (hierarchy) => {
    const content = hierarchy.trim();
    console.log(content)
    const idval = content.replace(/\$./g, '').trim(); 
    //const convertedToJavascriptJsonPath= convertBracketsToDots(content);
    const targetDiv = document.getElementById(idval);
    console.log(idval)
    if (targetDiv) {
      console.log(`Scrolling to and focusing on div with id: ${idval}`);
      targetDiv.focus();
     // targetDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
      //targetDiv.scrollIntoView({ behavior: 'smooth', block: "start"});
      
       setTimeout(() => {
      //   targetDiv.focus();
      targetDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
       }, 500);
    } else {
      console.error(`No element found with id: ${idval}`);
    }
  };

  function convertBracketsToDots(input) {
    console.log(input)
    return input.replace(/\[(\d+)\]/g, '.$1');
  }

  useEffect(() => {
    const handleSessionStorageUpdate = () => {
      setSaveFlag(sessionStorage.getItem("saveFlag"));
    };

    window.addEventListener(
      "sessionFlagUpdated",
      handleSessionStorageUpdate
    );

    return () => {
      window.removeEventListener(
        "sessionFlagUpdated",
        handleSessionStorageUpdate
      );
    };
  }, []);

  const replaceString = (originalString) => {
    const modifiedString = originalString
      .replace(/\$./g, ''); 
      var splited = modifiedString.split(".");
      var newString =splited;
      if(splited.length >1 ){
         newString = splited[splited.length-2];
      }
    
    return newString;
  };

  return (
    <>
      <div className="card  mb-3">
        <h5 class="card-header ">Edited Log Report</h5>

        <div ref={sectionRef} className="card-body ">
          <div className="table-responsive">
            <table class="table table-bordered">
              <thead >
                <tr>
                  <th scope="col">
                    Row
                  </th>
                  <th scope="col">Edited Field</th>
                  <th scope="col">Before Edit</th>
                  <th scope="col">Post Edit</th>
                  <th scope="col">Edited By</th>
                  <th scope="col">Edited On</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                    <td>1</td>
                    <td>
                    <a href="#" onClick={handleClick}>
                    {"$.tradeName.@none"}
                </a>
                    </td>
                    <td>{"MC tESTED"}</td>
                    <td>{"MC edited"}</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>
                    <a href="#" onClick={handleClick}>
                    {"$.formulationIngredients[0].label.@none"}
                </a>
                </td>
            
                    <td>{"MC okay"}</td>
                    <td>{"MC yes"}</td>
                </tr> */}
                 {nodes &&
                  nodes.length >= 1 &&
                  nodes.map((each, i) => (
                    <tr>
                      <th scope="row">{i+1}</th>
                      <td >
                      <a href="#" onClick={() =>handleClick(each.hierarchy)}>
                        {replaceString(each.hierarchy)}
                        </a></td>

                      <td>{each.beforeContent}</td>
                      <td>{each.afterContent}</td>
                      <td>{each.username}</td>
                      <td>
                        {each.timeStamp}
                      </td>
                    </tr>
                  ))}

                {(!nodes || nodes.length < 1) && (
                  <tr>
                    <td colSpan={6}>No data available</td>
                  </tr>
                )} 
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditedReport;
