import axios from "axios";
import { API_BASE_URL, LOGIN_NEEDED } from "../../Utils/Constant";
import LocalStorageService from "./AuthServiceStorage";

/**
 *
 * @version
 * @author: sandeep_k
 * @create date: 2020-02-05
 * @modified by: arjun.s
 * @modified date:  2020-03-04
 *
 * Auth Services - login , refresh access token, data request
 * Modified : Token refresh fixes by arjun.s
 */

/* Abstraction of the methods in Auth Service Constants */
const localService = LocalStorageService.getService();

/* -- Start of Axios Interceptors for Data Apis -- */
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use((request) => requestHandler(request));

/* Setting the headers for axios request */
const requestHandler = (request) => {
  request.headers["Authorization"] = localService.getAccessToken();
  return request;
};
/* -- End of Axios Interceptors for Data Apis -- */

/* -- Start of Axios Interceptors for Authentication Apis -- */
const authAxiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

/* -- End of Axios Interceptors for Authentication Apis -- */


axiosInstance.interceptors.response.use(null, async (error) => {


  if (error.config && error.response && error.response.status === 401) {
    // store.dispatch(openModal());
    return Promise.reject(error);
  }

  return Promise.reject(error);
});


/* Api request to fetch the data */
export const dataDisplayRequest = async (config) => {
  return await axiosInstance.request(config).then(function (response) {

    return Promise.resolve(response);
  });
};


/* Api request for login */
export const authLogin = (config) => {
  return authAxiosInstance
    .request(config)
    .then(function (response) {
      const data = response.data;
      localService.setToken(data);
      localService.setAuthDetails(JSON.stringify(data));
      //store.dispatch(closeModal());
      return Promise.resolve(response.data);
    })
    .catch(async function (error) {
      return Promise.reject(error);
    });
};

