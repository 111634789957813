import { BrowserRouter } from "react-router-dom";
import RoutesList from "./Routes";
import Login from "./Auth/Login/Login";

import ScrollToTopButton from "./Components/ScrollToTop/ScrollToTopButton";


import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <RoutesList />

      <Login />

      <ToastContainer />
      <ScrollToTopButton />
    </BrowserRouter>
  );
}

export default App;
