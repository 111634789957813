import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";


const ShowSectioncomment = ({ handleShowAllComment,slNo }) => {
  return (
    <>
 <Button
            variant="light"
            size="sm"
            className="ms-2"
            title="Show all comments"
            onClick={(e) => handleShowAllComment(slNo)}
          >
            <FontAwesomeIcon className="text-secondary" icon={faEye} />
          </Button>
    </>
  );
};

export default ShowSectioncomment;