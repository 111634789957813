import React , {useState, useEffect , useCallback} from "react";
import AxiosService from "../../Utils/AxiosService";
import LocalStorageService from "../../Auth/auth_service/AuthServiceStorage";
import { toast } from "react-toastify";
import jp from "jsonpath";
import "./EditorDiv.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
const EditableDetailRow = ({ data ,label, idField , editFlag , highlight}) => {
  const apiService = new AxiosService();
  const labelId = LocalStorageService.getSelectedLabelId();
  const authDetails = LocalStorageService.getAuthDetails();

  const [labelData, setLabelData] = useState(LocalStorageService.getSelectedLabelData());
  const [modifiedData, setModifiedData] = useState('');
  const [isEditable , setIsEditable] = useState(false);

  const handleBlur = useCallback((event) => {
    if (modifiedData && modifiedData !== event.target.innerText) {
      const newContent = event.target.innerText;
      const id = event.target.id;
      let id2 = decodeURI(id);
    console.log(id2);
    id = encodeURI(id);
    console.log(" id ",id);
      logInduvidualDataChanges(id, newContent, modifiedData);
    }
  }, [modifiedData]);
  
  const handleOnClick = useCallback((event) => {
    const id = event.target.id;
    if (id) {
      const newContent = document.getElementById(id).innerText;
      setModifiedData(newContent);
      setIsEditable(true);
      document.getElementById(id).classList.add("textarea-like");
    }
  }, []);
  
  const logInduvidualDataChanges = (id, content, oldContent) => {
    const authDetailsParsed = JSON.parse(authDetails);
  
    const saveData = {
      data: content,
      oldData: oldContent
    };
  
    apiService
      .LogIndividualData(id, saveData, labelId, authDetailsParsed.username)
      .then(() => {
        toast.success('Saved!');
        setModifiedData(content);
        LocalStorageService.setSaveFlag(true);
        window.dispatchEvent(new Event('sessionFlagUpdated'));
      })
      .catch(console.error);
  };
  
  // useEffect(() => {
  //   const handleSessionStorageUpdate = () => {
  //     setLabelData(LocalStorageService.getSelectedLabelData());
  //   };
  
  //   window.addEventListener('sessionFlagUpdated', handleSessionStorageUpdate);
  
  //   return () => {
  //     window.removeEventListener('sessionFlagUpdated', handleSessionStorageUpdate);
  //   };
  // }, []);

  const idValue = React.useMemo(() => {
    return idField;
  }, [idField]);
  
  // const idValue = React.useMemo(() => {
  //   try {
  //     if (idField) {
  //       const query = `$..${idField}`;
  //       const found = jp.paths(JSON.parse(labelData), query);
  //       return found.length > 0 ? found[0].join('.') : '';
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   return '';
  // }, [idField, labelData]);
  
  const renderEditableContent = () => {
    if (idValue) {
      return editFlag ? (
        <div
          id={idValue}
          contentEditable={isEditable}
        // contentEditable
          onBlur={handleBlur}
        //  suppressContentEditableWarning
          onClick={handleOnClick}
         // className="textarea-like"
        >
          {/* <div className="editIcon" title="Edit">
            <FontAwesomeIcon icon={faEdit} size="xs" />
          </div> */}
          {data}
        </div>
      ) : (
        <div id={idValue} 
       // className="textarea-like"
        >
          {data}
        </div>
      );
    }
    return (
      <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
        {data}
      </div>
    );
  };
  
  return (
    <>
      {data && (
        <>
          <div className="fw-bold">
          {highlight ? (<span className="bg-warning">{label}:</span>):(<>{label}: </>)}
          </div>
         {data &&  renderEditableContent()}
        </>
      )}
    </>
  );
};

export default EditableDetailRow;
