import React, { useState, useEffect } from "react";
import styles from "./Layout.module.scss";
import { Dropdown } from "react-bootstrap";
import logo from "../../assets/mcLogo.png";
import classNames from "classnames";
import LocalStorageService from "../../Auth/auth_service/AuthServiceStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
 import templateFile from "../../assets/template.xlsx";
import {
  faSignOut,
  faCog,
  faLifeRing,
  faUserAlt,
  faAngleDoubleRight,
  faLanguage,
  faBuildingShield,
  faGlobe,
  faDownload,
  faFilterCircleXmark
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import "./Header.css";
import { saveAs } from 'file-saver';
import AxiosService from "../../Utils/AxiosService";
import { MdDataObject } from "react-icons/md";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import { GrTemplate } from "react-icons/gr";

function Header() {
  const apiService = new AxiosService();
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [allRoles, setAllRoles] = useState([]);
  const [selectedRole, setSlectedRole] = useState("");
  const [selectLanguage, setSelectedLangage] = useState("");
  const [data, setData] = useState({});

  const [labelId, setLabelId] = useState(LocalStorageService.getSelectedLabelId());

  const localStorageService = LocalStorageService.getService();

  useEffect(() => {
    const authVal = localStorageService.getAuthDetails();

    if (authVal) {
      const isEmailFormat = JSON.parse(authVal)?.username?.includes("@");
      if (isEmailFormat) {
        const userName = JSON.parse(authVal)?.username?.split("@")[0];
        setUserName(userName);
      } else {
        setUserName(JSON.parse(authVal).username);
      }
      const roles = JSON.parse(authVal)?.roles;
      const resultMap = roles.reduce((acc, item) => {
        const [key, value] = item.split("-"); // Split each item by '-'

        if (!acc[key]) {
          acc[key] = []; // Initialize an array for the key if it doesn't exist
        }

        acc[key].push(value); // Push the second part to the array under the key

        return acc;
      }, {});
      console.log(resultMap);
      setData(resultMap);
      let roleOptions = [];
      roles.forEach((element) => {
        if (element && element.split("-")[1] === "GER") {
          roleOptions.push({
            label: element.split("-")[0] + "-DE",
            value: element,
          });
        } else {
          roleOptions.push({
            label: element,
            value: element,
          });
        }
      });
      setAllRoles(roleOptions);
    } else {
      toast.error("Please login to continue");
      navigate("/landing");
    }
  }, []);

  const onLogout = () => {
    logTheLogout();
    
  };

  const logTheLogout = () =>{
    const authVal = localStorageService.getAuthDetails();

    let authDetails = JSON.parse(authVal);
    apiService
    .logTheLogout(authDetails.id)
    .then((response) => {
      console.log(response)
    LocalStorageService.removeTokens();
    setUserName("");
    navigate("/landing");
    })
    .catch((error) => {
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
      } else {
        console.log("Something went wrong!");
      }
    });
  };

  const onSelectLanguage = (element) => {
    console.log(element);
    LocalStorageService.setSelectedLanguage(element);
    const sessionStorageUpdatedEvent = new Event("sessionStorageUpdated");
    window.dispatchEvent(sessionStorageUpdatedEvent);
    element = element.includes("-GER")
      ? element.replace("-GER", "-DE")
      : element;
    setSelectedLangage(element);
  };

  const handleDownload = () => {
    if (window.confirm("Do you want to download the json file?")) {
    apiService
      .getLabelDataForDownload(labelId)
      .then((response) => {
        if (response.data) {
            const fileName = response.data?.tradeName["@none"];
            const jsonBlob = new Blob([JSON.stringify(response.data, null, 2)], { type: 'application/json' });
            saveAs(jsonBlob, fileName+'.json');
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      });
    }
  };

  useEffect(() => {
    const handleSessionStorageUpdate = () => {
      setLabelId(sessionStorage.getItem("selectedLabelId"));
    };

    window.addEventListener(
      "sessionStorageLabelUpdated",
      handleSessionStorageUpdate
    );

    return () => {
      window.removeEventListener(
        "sessionStorageLabelUpdated",
        handleSessionStorageUpdate
      );
    };
  }, []);

  const handleExcelDownload = () => {
    if (window.confirm("Do you want to download the excel file?")) {
      apiService
        .getLabelDataForExcelDownload(labelId)
        .then((response) => {
          console.log(response)
          if (response.data) {
           console.log("Received data type:", response.data.constructor.name);
           console.log("Received data size:", response.data.byteLength);
 const excelBlob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
 saveAs(excelBlob, 'data.xlsx');
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error("Something went wrong!");
          }
          console.log(error)
        });
    }
  };

  return (
    <>
      <header className={classNames(styles.header, "shadow-sm  bg-dark pe-3 ")}>
        <section className="container-xxl my-2">
          <div className="d-flex align-items-center justify-content-between">
            <img src={logo} height={60} alt="logo" />
            {userName && (
              <div className="d-flex align-items-center">
              <div className="download-button-container me-2">
                <button
                  className="download-button"
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = templateFile; 
                    link.download = 'template.xls';
                    link.click(); 
                  }}
                  title="Download Template"
                >
                  <GrTemplate />
                </button>
              </div>

                 {labelId && (
                  <div className="download-button-container">
                    <Dropdown
                      className="me-2"
                      // onClick={handleDownload}
                      title="Export"
                    >
                      <Dropdown.Toggle
                        variant="light"
                        size="sm"
                        id="dropdown-basic"
                      >
                        <FontAwesomeIcon className="" icon={faDownload} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item as="button" onClick={handleDownload}>
                          <MdDataObject />
                          <span className="ml-2"> Export as JSON</span>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item as="button" onClick={handleExcelDownload}>
                          <PiMicrosoftExcelLogoFill />
                          <span className="ml-2"> Export as Excel</span>
                        </Dropdown.Item>
                        {/* <Dropdown.Divider />
                        <Dropdown.Item as="a"  href={templateFile} download="template.xls">
                       
                          <span className="ml-2"> Download Template</span>
                        </Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}

              
              <Dropdown className="me-4">
                <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic">
                  <FontAwesomeIcon icon={faUserAlt} />
                  <span className="ml-2"> Welcome, {userName}</span>{" "}
                  {selectLanguage && (
                    <span className=" me-2 ml-2">({selectLanguage})</span>
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {/* <Dropdown
                    as="div"
                    className="nested-dropdown-container"
                    drop="end"
                  >
                    <Dropdown.Toggle
                      as="div"
                      variant="light"
                      className="nested-dropdown"
                    >
                      <FontAwesomeIcon icon={faLanguage} className="me-2" />
                      <span className="ml-2">Client</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="nested-dropdown-menu" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                      {/* <Dropdown.Item as={Button} key={""} onClick={() => onSelectLanguage("")}>All</Dropdown.Item> 
                      {allRoles &&
                        allRoles.map((element, index) => (
                         
                          <Dropdown.Item
                            as={Button}
                            key={index}
                            onClick={() => onSelectLanguage(element)}
                          >
                            {element.label}
                          </Dropdown.Item>
                         
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>  */}

                  <Dropdown
                    as="div"
                    className="nested-dropdown-container"
                    drop="start"
                  >
                    <Dropdown.Toggle
                      as="div"
                      variant="light"
                      className="nested-dropdown"
                    >
                      {/* <FontAwesomeIcon icon={faLanguage} className="me-2" />
                      <span className="ml-2">Client</span> */}
                      <FontAwesomeIcon
                        icon={faGlobe}
                        className="me-2"
                        beatFade
                      />
                      <span className="ml-2">Organization</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="nested-dropdown-menu">
                      {/* style={{ maxHeight: '200px', overflowY: 'auto' }}> */}
                      {Object.keys(data).map((key) => (
                        <Dropdown
                          as="div"
                          className="nested-dropdown-container1"
                          drop="start"
                        >
                          <Dropdown.Toggle
                            as="div"
                            variant="light"
                            className="nested-dropdown1"
                          >
                            <span className="ml-2">{key}</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="nested-dropdown-menu1">
                            {data[key].map((subItem) => (
                              <Dropdown.Item
                                as={Button}
                                key={`${key}-${
                                  subItem === "GER" ? "DE" : subItem
                                }`}
                                onClick={() =>
                                  onSelectLanguage(`${key}-${subItem}`)
                                }
                              >
                                {subItem === "GER" ? "DE" : subItem}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown.Divider />
                  <Dropdown.Item as="button" onClick={() =>
                                  onSelectLanguage("")
                                }>
                    <FontAwesomeIcon icon={faFilterCircleXmark} fade/>
                    <span className="ml-2"> Reset Organization</span>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item as="button" onClick={() => onLogout()}>
                    <FontAwesomeIcon icon={faSignOut} />
                    <span className="ml-2"> Logout</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              </div>
            )}
          </div>
        </section>
      </header>
    </>
  );
}

export default Header;
