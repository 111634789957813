import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ExpandToggle = ({ boolToggle, onToggle }) => {
  console.log(boolToggle)
  return (
    <>
      <OverlayTrigger
        overlay={
          <Tooltip id="tooltip-disabled">
            {boolToggle ? "Collapse section" : "Expand section"  }
          </Tooltip>
        }
      >
        <span className="d-inline-block">
          <Button
            variant="outline-success"
            size="sm"
            className="ms-2"
            onClick={onToggle}
          >
            <FontAwesomeIcon
              icon={boolToggle ? faAngleDoubleUp : faAngleDoubleDown}
            />
          </Button>
        </span>
      </OverlayTrigger>
    </>
  );
};

export default ExpandToggle;
