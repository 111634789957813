import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
  faCalendarDays,
  faComment,
  faUser,
  faEdit,
  faTrash,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import Popover from "react-bootstrap/Popover";
import Form from "react-bootstrap/Form";
import DateFormat from "./DateFormat";
import LocalStorageService from "../../../Auth/auth_service/AuthServiceStorage";
import AxiosService from "../../../Utils/AxiosService";

const Comments = ({
  secNo,
  commentsLoader,
  saveLoader,
  onCommentsSave,
  savedComments,
  onCommentReloader,
}) => {
  const [comment, setComment] = useState("");
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);

  const [validated, setValidated] = useState(false);
  const authDetails = LocalStorageService.getAuthDetails();
  const label = localStorage.getItem("selectedLabel");
  const [openEditPopovers, setOpenEditPopovers] = useState({});

  const [commentData, setCommentData] = useState({});
  const [commentTempData, setCommentTempData] = useState("");
  const [editLoader, setEditLoader] = useState(false);

  const [commentState, setCommentState] = useState({});

  const auth_details = JSON.parse(authDetails);
  const apiService = new AxiosService();

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity()) {
      if(comment !==null && comment.trim() !==""){
        setShow(false);
        onCommentsSave(comment.trim(), secNo);
      }else{
        toast.warning("Comment cannot be empty!");
      }
     
    }
  };

  const popover = (
    <Popover style={{ minWidth: 500 }} id="popover-basic">
      <Popover.Header as="h3">Add Comments</Popover.Header>
      <Popover.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Control
              value={comment}
              required
              onChange={(e) => setComment(e.target.value)}
              as="textarea"
              rows={3}
            />
            <Form.Control.Feedback type="invalid">
              Please enter comments.
            </Form.Control.Feedback>
          </Form.Group>

          <Button variant="success" disabled={saveLoader} type="submit">
            Submit
          </Button>

          <Button
            variant="secondary"
            className="ms-2"
            onClick={(e) =>{
              setComment("");
              setShow(false)
            } }
          >
            Close
          </Button>
        </Form>
      </Popover.Body>
    </Popover>
  );

  const dateOut = (date) => {
    return (
      <>
        <div>
          <FontAwesomeIcon icon={faCalendarDays} className="me-2" />
      {date}
          {/* <DateFormat data={date} /> */}
        </div>
      </>
    );
  };

  const renderEditPopover = () => {
    if (!commentData) return null;

    return (
      <Popover
        id={`edit-popover-${commentData.cmtId}`}
        style={{ minWidth: 500 }}
      >
        <Popover.Header as="h3">Edit Comments</Popover.Header>
        <Popover.Body>
          <Form noValidate validated={validated} onSubmit={handleEditSubmit}>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea2"
            >
              <Form.Control
                value={commentData.comment}
                required
                as="textarea"
                defaultValue={commentState[commentData.cmtId] || ""}
                onChange={(e) => handleOnChangeCommentTextArea(e)}
                rows={3}
              />
              <Form.Control.Feedback type="invalid">
                Please enter comments.
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="success" disabled={saveLoader} type="submit">
              Update
            </Button>

            <Button
              variant="secondary"
              className="ms-2"
              onClick={() =>
                setOpenEditPopovers((prevState) => ({
                  ...prevState,
                  [commentData.cmtId]: false,
                }))
              }
            >
              Close
            </Button>
          </Form>
        </Popover.Body>
      </Popover>
    );
  };

  const handleEditComment = (data) => {
    setCommentData(data);
    setCommentTempData(data.comment);
    setOpenEditPopovers((prevState) => ({
      ...prevState,
      [data.cmtId]: !prevState[data.cmtId],
    }));
  };

  const handleOnChangeCommentTextArea = (e) => {
    // setCommentState(prevState => ({ ...prevState, [commentData.cmtId]: e.target.value }));
    setCommentData({ ...commentData, comment: e.target.value });
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity()) {
     
       if(commentData.comment !==null && commentData.comment.trim() !== ""){
        setOpenEditPopovers((prevState) => ({
          ...prevState,
          [commentData.cmtId]: false,
        }));
        editComment(commentData, label);
       }else{
        toast.warning("Comment cannot be empty!");
        setCommentData({ ...commentData, comment: commentTempData });
        setCommentTempData("");
       }
      
    }
  };

  const editComment = (commentData, label) => {

    if(commentData.comment !== null && commentData.comment.trim() !==""){
      setEditLoader(true);
    const editData = {
      data: { cmtId: commentData.cmtId, comment: commentData.comment.trim() },
    };
    apiService
      .editComment(editData, label)
      .then((response) => {
        setEditLoader(false);
        if (response.data) {
          onCommentReloader(true);
          toast.success("Comment updated successfully!");
          setCommentState((prevState) => ({
            ...prevState,
            [commentData.cmtId]: commentData.comment,
          }));

          setOpenEditPopovers((prevState) => ({
            ...prevState,
            [commentData.cmtId]: false,
          }));
        }
      })
      .catch((error) => {
        setEditLoader(false);

        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      });
    }else{
      toast.warning("Comment cannot be empty!");
    }
    
  };

  const handleDeleteComment = (commentId) => {
    if (
      window.confirm(
        `Do you want to delete the selected comment?`
      )
    ) {
      setEditLoader(true);
      apiService
        .deleteComment(commentId, label)
        .then((response) => {
          setEditLoader(false);
          if (response.data) {
            onCommentReloader(true);
            toast.success("Comment deleted successfully!");
            setCommentState((prevState) => ({
              ...prevState,
              [commentData.cmtId]: commentData.comment,
            }));

            setOpenEditPopovers((prevState) => ({
              ...prevState,
              [commentData.cmtId]: false,
            }));
          }
        })
        .catch((error) => {
          setEditLoader(false);
          console.log(error)

          if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error("Something went wrong!");
          }
        });
    }
  };


  const handleHideComment = (commentId) => {
    // if (
    //   window.confirm(
    //     `Do you want to hide the selected comment?`
    //   )
    // ) {
      setEditLoader(true);
      apiService
        .hideComment(commentId, label)
        .then((response) => {
          setEditLoader(false);
          if (response.data) {
            onCommentReloader(true);
            toast.success("Comment was hidden successfully.!");
            setCommentState((prevState) => ({
              ...prevState,
              [commentData.cmtId]: commentData.comment,
            }));

            setOpenEditPopovers((prevState) => ({
              ...prevState,
              [commentData.cmtId]: false,
            }));
          }
        })
        .catch((error) => {
          setEditLoader(false);
          console.log(error)

          if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error("Something went wrong!");
          }
        });
    //}
  };


  return (
    <>
      <OverlayTrigger
        show={show}
        rootClose={true}
        placement="right"
        overlay={popover}
      >
        <Button
          variant="light"
          onClick={(e) =>{
            setComment(" ");
            setShow(true);
          } }
          size="sm"
          className="ms-2"
          title="Add comment"
        >
          {commentsLoader || saveLoader ? (
            <>
              <Spinner animation="grow" size="sm" />
              <Spinner animation="grow" size="sm" />
            </>
          ) : (
            <FontAwesomeIcon className="text-success" icon={faComment} />
          )}
        </Button>
      </OverlayTrigger>
      {savedComments &&
        savedComments.map((each) => (
          <>
            {each.sectionNo === secNo && (
              <>
                <div className="card bg-secondary text-white my-2">
                  <div className="card-body">
                    {/* <p id={`comment-${each.cmtId}`} style={{ fontSize: 16 }}>{each.comment}</p> */}
                    <p id={`comment-${each.cmtId}`} style={{ fontSize: 16 }}>
                      {commentState[each.cmtId] || each.comment}
                    </p>
                    {auth_details && auth_details.id === each.userId && (
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <OverlayTrigger
                          key={each.cmtId}
                          show={openEditPopovers[each.cmtId]}
                          rootClose={true}
                          placement="right"
                          overlay={renderEditPopover()}
                          trigger="click"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Button
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              padding: 0,
                              cursor: "pointer",
                            }}
                            title="Edit"
                            size="sm"
                            onClick={() => handleEditComment(each)}
                          >
                            <FontAwesomeIcon
                              icon={faEdit}
                              style={{ color: "#A5F58A" }}
                              className="me-2"
                            />
                          </Button>
                        
                        </OverlayTrigger>
                        <Button
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                padding: 0,
                                cursor: "pointer",
                              }}
                              title="Delete"
                              onClick={(e) => handleDeleteComment(each.cmtId)}
                              size="sm"
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{ color: "#F9B4AF" }}
                                className="me-2"
                              />
                            </Button>
                            <Button
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                padding: 0,
                                cursor: "pointer",
                              }}
                              title="Hide comment"
                              onClick={(e) => handleHideComment(each.cmtId)}
                              size="sm"
                            >
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                style={{ color: "#FFD43B" }}
                                className="me-2"
                              />
                            </Button>
                      </div>
                    )}
                  </div>

                  <div
                    style={{ fontSize: 13 }}
                    class="card-footer text-body-light"
                  >
                    <FontAwesomeIcon icon={faUser} className="me-2" />{" "}
                    {each.username} {each.timeStamp && dateOut(each.timeStamp)}
                  </div>
                </div>
              </>
            )}
          </>
        ))}
    </>
  );
};

export default Comments;
