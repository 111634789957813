/**
 *
 * @version
 * @author: sandeep_k
 * @create date: 2020-01-19
 *
 * Functions to handle Local Storage Items
 */

import { handleEncrypt, handleDecrypt } from "../../Utils/Crypto";

const LocalStorageService = (function() {

  var _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  function _setToken(tokenObj) {
    sessionStorage.setItem("access_token", tokenObj.accessToken);
  }



  function _getAccessToken() {
    return sessionStorage.getItem("access_token");
  }



  function _removeTokens() {
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("auth_details");
    sessionStorage.removeItem("selectedLanguage");
    sessionStorage.removeItem("selectedLabelData");
    sessionStorage.removeItem("selectedLabelId");
    sessionStorage.removeItem("saveFlag");
  }



  function _setAuthDetails(auth_details) {
    sessionStorage.setItem("auth_details", auth_details);
  }

  function _getAuthDetails() {
    return sessionStorage.getItem("auth_details");
  }

  function _setSelectedLanguage(language) {
    sessionStorage.setItem("selectedLanguage", language);
  }

  function _getSelectLanguage() {
    return sessionStorage.getItem("selectedLanguage");
  }

  function _setSelectedLabelId(labelId) {
    sessionStorage.setItem("selectedLabelId", labelId);
  }

  function _getSelectLabelId() {
    return sessionStorage.getItem("selectedLabelId");
  }

  function _setSelectedLabelData(labelData) {
    const encrypted = handleEncrypt(JSON.stringify(labelData));
    sessionStorage.setItem("selectedLabelData", encrypted);
  }

  function _getSelectedLabelData() {
    const decrypted = JSON.parse(handleDecrypt(sessionStorage.getItem("selectedLabelData")));
    return decrypted;
  }

  function _setSaveFlag(flag) {
    sessionStorage.setItem("saveFlag", flag);
  }

  function _getSaveFlag() {
    return sessionStorage.getItem("saveFlag");
  }

  return {
    getService: _getService,
    setToken: _setToken,
    setAuthDetails: _setAuthDetails,
    getAuthDetails: _getAuthDetails,
    getAccessToken: _getAccessToken,
    removeTokens: _removeTokens,
    getSelectedLanguage : _getSelectLanguage,
    setSelectedLanguage : _setSelectedLanguage,
    getSelectedLabelId : _getSelectLabelId,
    setSelectedLabelId :_setSelectedLabelId,
    getSelectedLabelData : _getSelectedLabelData,
    setSelectedLabelData :_setSelectedLabelData,
    getSaveFlag : _getSaveFlag,
    setSaveFlag : _setSaveFlag
  };
})();

export default LocalStorageService;
/* Function to handle Local Storage Items */
