import React, { useEffect, useState } from "react";
import "./FloatingSaveButton.css"; // Create a separate CSS file for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import LocalStorageService from "../../Auth/auth_service/AuthServiceStorage";
import AxiosService from "../../Utils/AxiosService";
import { toast } from "react-toastify";

const FloatingSaveButton = () => {
  const apiService = new AxiosService();
  const labelId = LocalStorageService.getSelectedLabelId();
  const authDetails = LocalStorageService.getAuthDetails();
  const [saveFlag, setSaveFlag] = useState(false);
  const [labelData , setLabelData] = useState(LocalStorageService.getSelectedLabelData);

  const handleSave = () => {
    if (window.confirm("Are you sure you want to save changes?")) {
     
      const auth_details = JSON.parse(authDetails);
      const parsedFinalData = JSON.parse(labelData);
      const saveData = {
        data: parsedFinalData,
      };
      apiService
        .updateData(1, saveData, labelId, auth_details.username)
        .then((response) => {
          console.log(response);
          LocalStorageService.setSaveFlag(false);
          setSaveFlag(false);
          toast.success("Data updated successfully!!");
        })
        .catch((error) => {
            toast.error("Something went wrong!!")
          console.log(error);
        });
    }
  };

  useEffect(() => {
    const handleSessionStorageUpdate = () => {
      setSaveFlag(sessionStorage.getItem("saveFlag"));
      setLabelData(LocalStorageService.getSelectedLabelData);
    };

    window.addEventListener(
      "sessionStorageFlagUpdated",
      handleSessionStorageUpdate
    );

    return () => {
      window.removeEventListener(
        "sessionStorageFlagUpdated",
        handleSessionStorageUpdate
      );
    };
  }, []);

  useEffect(() => {}, [saveFlag]);
  return (
    <>
      {saveFlag && (
        <div className="floating-save-button-container">
          <button className="floating-save-button" onClick={handleSave} title="save">
            <FontAwesomeIcon className="" icon={faSave} />
          </button>
        </div>
      )}
    </>
  );
};

export default FloatingSaveButton;
