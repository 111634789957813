import { ActionType } from "../Action/Types"

const initialState = {
    tokenRefresh: false,
}

const tokenReducer = (state=initialState, action) => {
    switch(action.type){
        case ActionType.TOKEN_EXPIRED:
            return{...state, tokenRefresh: action.payload}
        case ActionType.TOKEN_REFRESHED:
            return{...state, tokenRefresh: action.payload}
        default:
            return state;
    }
}

export default tokenReducer;