import moment from "moment";

const DateFormat = ({ data }) => {

    let output = ""

    if(data) {
        output = moment(data).format('DD/MM/YYYY, h:mm:ss a');
    }

  return <>{output}</>;
};

export default DateFormat;
