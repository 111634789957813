import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import AxiosService from "../../../Utils/AxiosService";


const CvAdditionComments = ({ label,data, sectionRef }) => {
    const apiService = new AxiosService();
    const [cvAdditionomment,setCvAdditionomment] = useState("");



    useEffect(() => {
        apiService
          .getCvAdditionComment(label)
          .then((response) => {
            if (response.data) {
                setCvAdditionomment(response.data);
            }
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              toast.error(error.response.data.message);
            } else {
              toast.error("Something went wrong!");
            }
          });
      }, [data]);


      const DynamicTables = ({ data }) => {
        return (
          data && (
            <div className="card mb-3">
              <h5 className="card-header">CV Addition Comment</h5>
              <div className="card-body">
                <div className="table-responsive">
                  {/* {
  Object.keys(data).length > 0 ? (
    Object.keys(data).map((key) => {
      const entry = data[key];
      const headers = Object.keys(entry);

      // Find the maximum number of values in any of the fields (array length)
      const maxArrayLength = Math.max(...headers.map(header => 
        Array.isArray(entry[header]) ? entry[header].length : 1
      ));

      return (
        <div key={key}>
          <h3>Table for {key}</h3>
          <table className="table table-bordered">
            <thead>
              <tr>
                {headers.map((header) => {
                  const values = entry[header];
                  // If the value is an array, repeat the header for each item
                  return Array.isArray(values)
                    ? values.map((_, subIndex) => (
                        <th key={`${header}-${subIndex}`}>{header}</th>
                      ))
                    : <th key={header}>{header}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                {headers.map((header) => {
                  const values = entry[header];
                  return Array.isArray(values)
                    ? values.map((value, subIndex) => (
                        <td key={`${header}-${subIndex}`}>{value}</td>
                      ))
                    : <td key={header}>{values}</td>;
                })}
              </tr>
            </tbody>
          </table>
        </div>
      );
    })
  ) : (
    <p>No data available</p>
  )
} */}

                 {Object.keys(data).length > 0 ? (
                  Object.keys(data).map((key) => {
                    const entry = data[key];
      
                    return (
                      <table key={key} className="table table-bordered">
                        <thead>
                          <tr>
                            {Object.keys(entry).map((header) => (
                              <th key={header} scope="col">{header}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {Object.values(entry).map((value, index) => (
                              <td key={index}>{value}</td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    );
                  })
                ) : (
                  <p>No data available</p>
                )} 
              
                </div>
              </div>
            </div>
          )
        );
      };

      
  return (
    <div ref={sectionRef}>
 <DynamicTables data={cvAdditionomment} />

    </div>
    // <div ref={sectionRef}>
    // {cvAdditionomment && 
    //     <div className="card mb-3">
    //     <h5 class="card-header ">CV Addition Comment</h5>
    //     <div className="card-body " ref={sectionRef}>
       
    //     <div ref={sectionRef} className="card-body ">
    //       <div className="table-responsive">
    //       <table class="table table-bordered">
    //           <thead>
    //             <tr>
    //               <th style={{ width: "10%" }} scope="col">
    //                 Sl No
    //               </th>
    //               <th scope="col">CV Field</th>
    //               <th scope="col">Comments</th>
                  
    //             </tr>
    //           </thead>
    //           <tbody>
    //              {cvAdditionomment &&
    //               cvAdditionomment.length >= 1 &&
    //               cvAdditionomment.map((each, i) => (
    //                 <tr>
    //                   <th scope="row">{i+1}</th>

    //                   <td>{each.cvName}</td>
    //                   <td>{each.comments}</td>
    //                 </tr>
    //               ))}

    //             {(!cvAdditionomment || cvAdditionomment.length < 1) && (
    //               <tr>
    //                 <td colSpan={3}>No CV addition available</td>
    //               </tr>
    //             )} 
    //           </tbody>
    //         </table>
    //       </div>
    //     </div>
    //     </div>
    //   </div>
    // }
    // </div>
   
  );
};

export default CvAdditionComments;
