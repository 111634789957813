import React, { useEffect, useState } from "react";
import Select from "react-select";


const Reactselect = ({ options, selectedLabel, handleChange, loader }) => {

  const [groupedOptions, setGroupedOptions] = useState([]);

  const getColorFromLabel = (label) => {
    //const match = label.match(/-- \((\d+)\)/);
    const number = label ? parseInt(label, 10) : null;
    
    switch (number) {
      case 1:
        return '#9bf2a7';
      case 2:
        return '#f7ff9c';
      case 3:
        return '#fad38c';
        default:
          return number > 3 ? '#f05656' : 'white';
    }
  };

  
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: getColorFromLabel(state.data.COUNT),
      color: 'black', 
    }),
  };
  const langLabels = {
    EN: 'English',
    ES: 'Spanish',
    FR: 'French',
    GER: 'Germany',
    RO: 'Romania',
    IT: 'Italy'
  };

  useEffect(() => {
    // Transform options to grouped options
    const grouped = options.reduce((acc, option) => {
      const groupLabel = langLabels[option.LANG] || option.LANG;
      const group = acc.find(g => g.label === groupLabel);
      if (group) {
        group.options.push(option);
      } else {
        acc.push({
          label: groupLabel,
          options: [option]
        });
      }
      return acc;
    }, []);
    setGroupedOptions(grouped);

  },  [options]);

  return (
    <>
      <Select
        options={groupedOptions}
        isLoading={loader}
        value={selectedLabel}
        onChange={handleChange}
        getOptionLabel={(option) => option.LABEL_NO}
        getOptionValue={(option) => option.LABEL_ID}
        placeholder="Select label.."
        // styles={customStyles}
      />
    </>
  );
};

export default Reactselect;
