import TableCell from "../TableCell";
import DetailRow from "../DetailRow";
import EditableTableCell from "../EditableTableCell";
import LabelWithInfoTooltip from "../Components/InfoToolTips";

const CropEvent = ({ data ,idField , editFlag}) => {

  console.log(data)
  return (
    <>
      {/* {cropEventData.map((data, i) => ( */}
      <>
        <div className="fw-bold">Relative To Event:</div>

        <div className="table-responsive">
          <table class="table  mt-2  table-bordered mb-0">
            <thead>
              <tr className="table-light">
                <th style={{ width: "30%" }} scope="col">
                  Label
                </th>
                <th scope="col">Type</th>
                <th scope="col">Additional Fields</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <TableCell
                    enableEdit={true}
                    editFlag={editFlag}
                    data={data.label ? data.label["@none"] : ""}
                    idField={`${idField}.label["@none"]`}
                  />
                </td>
                <td>
                  <TableCell data={data.type} />
                </td>

                <td>
                  {data.insect && (
                    <DetailRow
                      label={"Insect"}
                      data={data.insect.label["@none"]}
                      tooltipId={"cropevent-insect"}
                      tooltipInfo={"The insect pest associated with the event"}
                    />
                  )}

                  {data.ploughedPriorToSowingPlanting && (
                    <>
                      <div>
                        <span className="fw-bold">
                          Ploughed Prior To Sowing Planting:
                        </span>
                        <LabelWithInfoTooltip
                          info={
                            "Flags whether the ground was ploughed prior to the crop being sown or planted out"
                          }
                          id={"ploughedPriorToSowingPlanting"}
                        />
                      </div>
                      <TableCell
                        //enableEdit={true}
                        // editFlag={editFlag}
                        data={data.ploughedPriorToSowingPlanting.toString()}
                        // idField ={`${idField}.ploughedPriorToSowingPlanting`}
                      />
                    </>
                  )}

                  {data.crop && data.crop.label && (
                    <DetailRow label={"Crop"} data={data.crop.label["@none"]} tooltipId={"crop"} tooltipInfo={
                      "The crop with which the event is associated"
                    }/>
                  )}

                  {data.pest && data.pest.label && (
                    <DetailRow label={"Pest"} data={data.pest.label["@none"]} />
                  )}

                  {data.weed && (
                    <DetailRow label={"Weed"} data={data.weed.label["@none"]} />
                  )}

                  {data.fungus && (
                    <DetailRow
                      label={"Fungus"}
                      data={data.fungus.label["@none"]}
                      tooltipId={"cropevent-fungus"}
                      tooltipInfo={"The fungus species or species group to which the target applies"}
                    />
                  )}

                  {data.fungalLifecycleStage && (
                    <DetailRow
                      label={"Fungal Life cycle Stage"}
                      data={data.fungalLifecycleStage.label["@none"]}
                      tooltipId={"fungalLifecycleStage"}
                      tooltipInfo={"The fungal lifecycle stage associated with the event"}
                    />
                  )}

                  {data.cropGrowthStage && (
                    <DetailRow
                      label={"Crop Growth Stage"}
                      data={data.cropGrowthStage.label["@none"]}
                    />
                  )}
                  {data.pestDevelopmentStage && (
                    <DetailRow
                      label={"Pest Development Stage"}
                      data={data.pestDevelopmentStage.label["@none"]}
                    />
                  )}

                  {data.generalLifecycleStage &&
                    data.type === "GeneralLifecycleEvent" && (
                      <DetailRow
                        label={" General Life cycle Stage"}
                        data={data.generalLifecycleStage.label["@none"]}
                        tooltipId={"generalLifecycleStage"}
                        tooltipInfo={"The general agronomic lifecycle stage associated with the event"}
                      />
                    )}
                {data.generalLifecycleStage &&
                    data.type === "LifecycleEvent" && (
                      <DetailRow
                        label={"Life cycle Stage"}
                        data={data.generalLifecycleStage.label["@none"]}
                      />
                    )}

                  {data.pestLifecycleStage && (
                    <DetailRow
                      label={"Pest Life cycle Stage"}
                      data={data.pestLifecycleStage.label["@none"]}
                      tooltipId={"pestLifecycleStage"}
                      tooltipInfo={"The insect lifecycle stage associated with the event"}
                    />
                  )}

                  {data.weedLifecycleStage && (
                    <DetailRow
                      label={"Weed Life cycle Stage"}
                      data={data.weedLifecycleStage.label["@none"]}
                      tooltipId={"weedLifecycleStage"}
                      tooltipInfo={"The weed lifecycle stage associated with the event"}
                    />
                  )}

                  {data.weedGrowthStage && (
                    <DetailRow
                      label={"Weed Growth Stage"}
                      data={data.weedGrowthStage.label["@none"]}
                      tooltipId={"weedGrowthStage"}
                      tooltipInfo={"The weed growth stage associated with the event"}
                    />
                  )}

                  {data.growthStage && (
                    <DetailRow
                      label={"Growth Stage"}
                      data={data.growthStage.label["@none"]}
                      tooltipId={"growthStage"}
                      tooltipInfo={"The crop growth stage"}
                    />
                  )}

                  {data.cropLifecycleStage && (
                    <DetailRow
                      label={"Crop Life cycle Stage"}
                      data={data.cropLifecycleStage.label["@none"]}
                      tooltipId={"cropLifecycleStage"}
                      tooltipInfo={"The crop lifecycle stage associated with the event"}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
      {/* ))}*/}
    </>
  );
 
};

export default CropEvent;
