import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  faEnvelope,
  faLock,
  faUser,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../MasterLayout/Layout.module.scss";
import logo from "../../assets/mcLogo.png";

// import mclogo from "../../assets/mclogoOrange.png";
import mclogo from "../../assets/Digital-Now-3.png";

import classNames from "classnames";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { authLogin } from "../../Auth/auth_service/AuthServiceHelper";
import Footer from "../MasterLayout/Footer";
import DisclaimerPopup from "../MasterLayout/Discliamer";
import AxiosService from "../../Utils/AxiosService";
import LocalStorageService from "../../Auth/auth_service/AuthServiceStorage";

const Landing = () => {
  const navigate = useNavigate();
  const apiService = new AxiosService();
 

  const [show, setShow] = useState(false);

  const [loader, setLoader] = useState(false);

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const [validated, setValidated] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity()) {
      getLoginAPI();
    }
  };

  const getLoginAPI = () => {
    setLoader(true);

    const config = {
      url: "/api/v1/auth/login",
      method: "post",
      data: {
        username: name,
        password: password,
      },
    };

    authLogin(config)
      .then((response) => {
        setLoader(false);

        const auth_details = JSON.parse(LocalStorageService.getAuthDetails());
        if(auth_details && auth_details.disclaimerStatus === 'N'){
          setShow(false)
          console.log(auth_details)
          setShowDisclaimer(true);
        }else if(auth_details && auth_details.disclaimerStatus === 'Y'){
          navigate("/browse");
          toast.success("Successfully Logged In!");
        }
      })
      .catch((error) => {
        setLoader(false);

        let err = "Login failed";

        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          err = error.response.data.message;
        }

        toast.error(err);
      });
  };

  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const handleAcknowledgeDisclaimer = () => {

     let auth_details = JSON.parse(LocalStorageService.getAuthDetails());

    apiService
    .AcknowledgeDisclaimer(auth_details.id)
    .then((response) => {
    auth_details.disclaimerStatus ='Y';

    const updatedAuthDetails = JSON.stringify(auth_details);

    LocalStorageService.setAuthDetails(updatedAuthDetails);

    setShowDisclaimer(false);

    navigate("/browse");

    toast.success("Successfully Logged In!");
    })
    .catch((error) => {
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        setShowDisclaimer(false);
      } else {
        console.log("Something went wrong!");
        setShowDisclaimer(false);
      }
    });
  };

  const rejectAcknowledgeDisclaimer = () => {
      setShowDisclaimer(false);
      LocalStorageService.removeTokens();
      navigate("/landing");
  };

  return (
    <>
      {showDisclaimer && <DisclaimerPopup handleAcknowledge={handleAcknowledgeDisclaimer} show={showDisclaimer} rejectAcknowledge={rejectAcknowledgeDisclaimer}/>}
      <header className={classNames(styles.header, "shadow-sm  bg-dark pe-3 ")}>
        <section className="container-xxl my-2">
          <div className="d-flex justify-content-between align-items-center">
            <img src={logo} height={60} alt="logo" />
          </div>
        </section>
      </header>

      <main className="mcBackground">
        <div className="container ">
          <div className="row">
            <div className=" col-md-7 offset-md-4 text-white">
              <div className="mcBackgroundLogo mc-mt-5">
              </div>
              <div className="text-center mt-1">
              <Button onClick={handleShow} size="lg" variant="success">
                  <FontAwesomeIcon icon={faUser} className="me-2" /> Log In
              </Button>
              </div>
           
            </div>
          </div>
        </div>
      </main>

      <Footer />

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <div >
            <img className="img-fluid" src={mclogo} height={100} alt="logo" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            className="px-3 mb-2"
            onSubmit={handleSubmit}
          >
            {/* <h3>MC Digital Now</h3> */}

            <h6 className="mb-3" >Log in to your account</h6>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Email address</Form.Label>

              <InputGroup>
                <InputGroup.Text id="basic-addon1">
                  <FontAwesomeIcon icon={faEnvelope} />
                </InputGroup.Text>

                <Form.Control
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter username.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea2"
            >
              <Form.Label>Password</Form.Label>

              <InputGroup>
                <InputGroup.Text id="basic-addon1">
                  <FontAwesomeIcon icon={faLock} />
                </InputGroup.Text>
                <Form.Control
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter Password.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <div>
              <Button
                variant="primary"
                disabled={loader}
                size="lg"
                className="w-100 mt-2"
                type="submit"
              >
                {loader ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </>
                ) : (
                  <>
                    {" "}
                    <FontAwesomeIcon
                      icon={faRightFromBracket}
                      className="me-2"
                    />
                    Log In
                  </>
                )}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Landing;
