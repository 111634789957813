// import React , {useState, useEffect} from "react";
// import AxiosService from "../../Utils/AxiosService";
// import LocalStorageService from "../../Auth/auth_service/AuthServiceStorage";
// import { toast } from "react-toastify";
// const TableCell = ({ data }) => {
//   const apiService = new AxiosService();
//   const labelId = LocalStorageService.getSelectedLabelId();
//   const authDetails = LocalStorageService.getAuthDetails();
//   const [labelData, setLabelData] = useState(LocalStorageService.getSelectedLabelData);
//   const [modifiedData , setModifiedData] = useState("");


// const handleBlur = (event) => {
//   console.log(modifiedData)
//   if(modifiedData && modifiedData.split("@@@")[0] !== event.target.innerText){
//   const newContent = event.target.innerText;
//   const id = event.target.id;
//   console.log(event.target.id);
//   saveContent(id, newContent);
//   }
//   //setContent(newContent);
// };
// const saveContent = (id, content) => {
//     //const selectedLabelData = LocalStorageService.getSelectedLabelData();
//   console.log(`Saving content from ${id}: ${content}`);

//   const selectedMainJson = JSON.parse(labelData);

//   const modifiedObject = replaceValuesEndingWith2(selectedMainJson, content,id);
//   console.log(modifiedObject);
//   LocalStorageService.setSelectedLabelData(JSON.stringify(modifiedObject));
//   LocalStorageService.setSaveFlag(true);
//   setLabelData(JSON.stringify(modifiedObject));
//   const sessionStorageUpdatedEvent = new Event('sessionStorageFlagUpdated');
//   window.dispatchEvent(sessionStorageUpdatedEvent);
  
//   logInduvidualDataChanges(id,content);

// };

// function replaceValuesEndingWith2(obj, newValue, id) {

//   function recursiveSearch(o) {
//       for (let key in o) {
//           if (typeof o[key] === 'object' && o[key] !== null) {
//               // If the value is an object, recurse into it
//               recursiveSearch(o[key]);
//           } else if (typeof o[key] === 'string' && o[key].endsWith('@@@'+id)) {
//               // If the value is a string and ends with '@@@2', replace it with newValue
//               o[key] = newValue+"@@@"+id;
//           }else if(typeof o[key] === 'array' && o[key] !== null){
//             if (typeof o[key] === 'object' && o[key] !== null) {
//               recursiveSearch(o[key]);
//           } 
//           }
//       }
//   }

//   recursiveSearch(obj);
//   return obj;
// }

// let idValue="";
// if (null !== data && undefined !== data) {
//   if (typeof data !== "string") {
//     data = data;
//   } else if(typeof data ==="string") {
//     if (data.includes("@@@")) {
//       idValue = data.split("@@@")[1];
//       data = data.split("@@@")[0];
//     } else {
//       data = data;
//     }
//   }else{
//     data = data === 0 ? 0 : "-";
//   }
//   //setModifiedData(data);
// }

// const handleOnclick = (event) => {
//   const newContent = event.target.innerText;
//   const id = event.target.id;
//   console.log(newContent , id);
//   //saveContent(id, newContent);
//   //setContent(newContent);
//   setModifiedData(newContent+"@@@"+id);
// };

// const logInduvidualDataChanges = (id,content) =>{

//   const auth_details = JSON.parse(authDetails);
  
//   const saveData = {
//     data: content,
//   };
//   apiService
//     .LogIndividualData(id, saveData, labelId, auth_details.username)
//     .then((response) => {
//       console.log(response);
//     })
//     .catch((error) => {
//       console.log(error);
//     });

// };

// useEffect(() => {
//   const handleSessionStorageUpdate = () => {
//     setLabelData(LocalStorageService.getSelectedLabelData);
//   };

//   window.addEventListener('sessionStorageFlagUpdated', handleSessionStorageUpdate);

//   return () => {
//     window.removeEventListener('sessionStorageFlagUpdated', handleSessionStorageUpdate);
//   };
// }, []);
// return (
//   <>
//     {data && (
//       <>
//         {idValue && idValue !== "" ? (
//            <div id={idValue} contentEditable onBlur={handleBlur} suppressContentEditableWarning={true} onClick={handleOnclick}>
        
//             {data}
//           </div>
//         ) : (
//           <div>{data}</div>
//         )}
//       </>
//     )}
//   </>
// );
// };

// export default TableCell;

const TableCell = ({ data , idField ,enableEdit ,editFlag }) => {

const displayValue = data !== null && data !== undefined ? data : data === 0 ? 0 : "-";

const shouldEnableEdit = enableEdit && displayValue !== '-';

  return (
    <div
      id={idField}
      style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
      className={shouldEnableEdit ? "enableEdit" : ""}
    >
      {displayValue}
    </div>
  );
 };
 
 export default TableCell;
 
